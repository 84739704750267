import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

export const Header = styled.header<{ mobile: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${(props) =>
    props.mobile ? 'var(--spacing-xl) var(--spacing-md)' : 'var(--spacing-2xl) var(--spacing-3xl)'};
  border-bottom: 1px solid var(--border-hairline);

  > img {
    height: ${(props) => (props.mobile ? '1rem' : '1.5rem')};
  }
`;

export const Main = styled.main<{ mobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100%;
  width: 100%;
  max-width: ${(props) => (props.mobile ? 'unset' : '500px')};
  margin: 0 auto;
  padding: ${(props) => (props.mobile ? '0 var(--spacing-md)' : '0')};
  overflow: hidden;

  > img {
    height: ${(props) => (props.mobile ? '124px' : '172px')};
    width: ${(props) => (props.mobile ? '182px' : '254px')};
  }
`;
