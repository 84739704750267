import GoogleLogoSrc from '@/assets/google-logo.svg';
import InboxSrc from '@/assets/inbox.svg';
import WordmarkSrc from '@/assets/wordmark-primary.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { Container, Header, Main } from './OnboardingSignin.styles';

export interface OnboardingSigninProps {
  email: string;
  gmailSniperLink: string | null;
}

export function OnboardingSignin({ email, gmailSniperLink }: OnboardingSigninProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container>
      <Header mobile={isMobile}>
        <img src={WordmarkSrc} alt="Remento wordmark" />
      </Header>

      <Main mobile={isMobile}>
        <img src={InboxSrc} alt="Inbox icon" aria-hidden="true" />
        <RMSpacer spacing="2xl" direction="column" />
        <RMText as="h1" type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
          Check your inbox
        </RMText>
        <RMSpacer spacing={isMobile ? 'md' : '2xl'} direction="column" />
        <RMText type="sans" size="s" color="on-surface-secondary" style={{ textAlign: 'center' }} lineHeight="m">
          Verify your account by clicking the link we just sent to <b>{email}</b>.
        </RMText>
        <RMSpacer spacing={isMobile ? 'md' : '2xl'} direction="column" />
        {gmailSniperLink && (
          <a href={gmailSniperLink} target="_blank">
            <RMButton leftIcon={GoogleLogoSrc}>Open Gmail</RMButton>
          </a>
        )}
      </Main>
    </Container>
  );
}
